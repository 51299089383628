/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'train-lightrail-front': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0a.5.5 0 000 1h1v1.011c-1.525.064-3.346.394-4.588.655C1.775 2.904 1 3.915 1 5.055V13.5A2.5 2.5 0 003.5 16h9a2.5 2.5 0 002.5-2.5V5.055c0-1.14-.775-2.15-1.912-2.39-1.242-.26-3.063-.59-4.588-.654V1h1a.5.5 0 000-1zM8 3c1.497 0 3.505.356 4.883.644.653.137 1.117.722 1.117 1.411V13.5a1.5 1.5 0 01-1.072 1.438 76 76 0 01-.1-.792c-.092-.761-.2-1.752-.266-2.682A21 21 0 0112.5 10c0-1.051.143-2.404.278-3.435l.07-.522c.112-.798-.42-1.571-1.244-1.697C10.644 4.199 9.136 4 8 4s-2.645.2-3.604.346c-.825.126-1.356.9-1.244 1.697q.034.24.07.522C3.357 7.596 3.5 8.949 3.5 10c0 .428-.024.933-.062 1.464a57 57 0 01-.266 2.682c-.038.31-.072.58-.1.792A1.5 1.5 0 012 13.5V5.055c0-.69.464-1.274 1.117-1.41C4.495 3.354 6.503 3 8 3m3.835 11.266c.034.28.066.53.093.734H4.072a63 63 0 00.328-3h2.246c.36 0 .704-.143.958-.396a.35.35 0 01.25-.104h.292a.35.35 0 01.25.104c.254.253.599.396.958.396H11.6c.068.808.158 1.621.236 2.266zM6 13.5a.5.5 0 11-1 0 .5.5 0 011 0m0 0a.5.5 0 111 0 .5.5 0 01-1 0m3.5.5a.5.5 0 00.5-.5.5.5 0 101 0 .5.5 0 00-1 0 .5.5 0 10-.5.5m-5.03-3c.019-.353.03-.692.03-1 0-.927-.104-2.051-.216-3h7.432c-.112.949-.216 2.073-.216 3 0 .308.011.647.03 1H9.354a.35.35 0 01-.25-.104 1.35 1.35 0 00-.958-.396h-.292c-.36 0-.704.143-.958.396a.35.35 0 01-.25.104zm-.315-5l-.013-.096a.497.497 0 01.405-.57C5.505 5.188 6.947 5 8 5s2.495.188 3.453.334a.497.497 0 01.405.57L11.845 6z"/>',
    },
});
